module.exports = {
    cuba_states: [
        {
            name: "Artemisa",
            cities: [
                "Artemisa",
                "Alquizar",
                "Bahía Honda",
                "Bauta",
                "Caimito",
                "Candelaria",
                "Guanajay",
                "Güira de Melena",
                "Mariel",
                "San Antonio de Los Baños",
                "San Cristóbal",
            ]
        },
        {
            name: "Camagüey",
            cities: [
                "Camagüey",
                "Carlos Manuel de Céspedes",
                "Esmeralda",
                "Florida",
                "Guáimaro",
                "Jimaguayú",
                "Minas",
                "Najasa",
                "Nuevitas",
                "Santa Cruz del Sur",
                "Sibanicú",
                "Sierra de Cubitas",
                "Vertientes",
            ]
        },
        {
            name: "Ciego de Ávila",
            cities: [
                "Ciego de Ávila",
                "Baraguá",
                "Bolivia",
                "Chambas",
                "Ciro Redondo",
                "Florencia",
                "Majagua",
                "Morón",
                "Primero de Enero",
                "Venezuela",
            ]
        },
        {
            name: "Cienfuegos",
            cities: [
                "Cienfuegos",
                "Abreus",
                "Aguada de Pasajeros",
                "Cruces",
                "Cumanayagua",
                "Lajas",
                "Palmira",
                "Rodas",
            ]
        },
        {
            name: "Granma",
            cities: [
                "Bayamo",
                "Bartolomé Masó",
                "Buey Arriba",
                "Campechuela",
                "Cauto Cristo",
                "Guisa",
                "Jiguaní",
                "Manzanillo",
                "Media Luna",
                "Niquero",
                "Pilón",
                "Río Cauto",
                "Yara",
            ]
        },
        {
            name: "Guantánamo",
            cities: [
                "Guantánamo",
                "Baracoa",
                "Caimanera",
                "El Salvador",
                "Imías",
                "Maisí",
                "Manuel Tames",
                "Niceto Pérez",
                "San Antonio del Sur",
                "Yateras",
            ]
        },
        {
            name: "Holguín",
            cities: [
                "Holguín",
                "Antilla",
                "Báguanos",
                "Banes",
                "Cacocum",
                "Calixto García",
                "Cueto",
                "Frank País",
                "Gibara",
                "Mayarí",
                "Moa",
                "Rafael Freyre",
                "Sagua de Tánamo",
                "Urbano Noris",
            ]
        },
        {
            name: "La Habana",
            cities: [
                "Plaza de la Revolución",
                "Arroyo Naranjo",
                "Boyeros",
                "Centro Habana",
                "Cerro",
                "Cotorro",
                "Diez de Octubre",
                "Guanabacoa",
                "La Habana del Este",
                "La Habana Vieja",
                "La Lisa",
                "Marianao",
                "Playa",
                "Regla",
                "San Miguel del Padrón",
            ]
        },
        {
            name: "Las Tunas",
            cities: [
                "Las Tunas",
                "Amancio",
                "Colombia",
                "Jesús Menéndez",
                "Jobabo",
                "Majibacoa",
                "Manatí",
                "Puerto Padre",
            ]
        },
        {
            name: "Matanzas",
            cities: [
                "Matanzas",
                "Calimete",
                "Cárdenas",
                "Ciénaga de Zapata",
                "Colón",
                "Jagüey Grande",
                "Jovellanos",
                "Limonar",
                "Los Arabos",
                "Martí",
                "Pedro Betancourt",
                "Perico",
                "Unión de Reyes",
            ]
        },
        {
            name: "Mayabeque",
            cities: [
                "San José de Las Lajas",
                "Batabanó",
                "Bejucal",
                "Güines",
                "Jaruco",
                "Madruga",
                "Melena del Sur",
                "Nueva Paz",
                "Quivicán",
                "San Nicolás",
                "Santa Cruz del Norte",
            ]
        },
        {
            name: "Pinar del Río",
            cities: [
                "Pinar del Río",
                "Consolación del Sur",
                "Guane",
                "La Palma",
                "Los Palacios",
                "Mantua",
                "Minas de Matahambre",
                "San Juan y Martínez",
                "San Luis",
                "Sandino",
                "Viñales",
            ]
        },
        {
            name: "Sancti Spíritus",
            cities: [
                "Sancti Spíritus",
                "Cabaiguán",
                "Fomento",
                "Jatibonico",
                "La Sierpe",
                "Taguasco",
                "Trinidad",
                "Yaguajay",
            ]
        },
        {
            name: "Santiago de Cuba",
            cities: [
                "Santiago de Cuba",
                "Contramaestre",
                "Guamá",
                "Mella",
                "Palma Soriano",
                "San Luis",
                "Segundo Frente",
                "Songo - La Maya",
                "Tercer Frente",
            ]
        },
        {
            name: "Villa Clara",
            cities: [
                "Santa Clara",
                "Caibarién",
                "Camajuaní",
                "Cifuentes",
                "Corralillo",
                "Encrucijada",
                "Manicaragua",
                "Placetas",
                "Quemado de Güines",
                "Ranchuelo",
                "Remedios",
                "Sagua la Grande",
                "Santo Domingo",
            ]
        },
        {
            name: "Munic. Esp. Isla de la Juventud",
            cities: [
                "Munic. Esp. Isla de la Juventud",
            ]
        },
    ],
    business_categories: [
        "Tecnología",
        "Venta de garaje",
        "Ferretería",
        "Moda y belleza",
        "Servicios",
        "Entretenimiento",
        "Restaurante",
        "Cafetería",
        "Pizzería",
        "Alimentos varios",
        "Alojamiento",
        "Artesanía",
        "Tienda",
        "Otros",
    ],
    getFirstChar: (text) => {
        return text[0];
    },
}
