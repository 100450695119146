<template>
  <div>
    <v-toolbar color="primaryDark" dark>
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex">
            <img :src="require('@/assets/logo-right.svg')"
                 style="max-height: 50px">
            <v-toolbar-title class="pl-2 pt-2" style="font-size: 20px; width: 200px">
              <router-link :to="{name: 'home'}" style="text-decoration: none; color: white">
                SeOferta
              </router-link>
            </v-toolbar-title>
          </div>

          <div class="d-flex">
            <sign-in v-if="isLogged === false"></sign-in>
            <v-menu v-if="isLogged === true" min-width="300px" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="text-none ma-1 px-1"
                       color="primary"
                       depressed
                       large
                       rounded
                       v-bind="attrs"
                       v-on="on">
                  <v-icon class="ma-2" color="white">mdi-menu</v-icon>
                  <v-avatar class="pa-0" color="white" size="36">
                    <span style="color: #008081; font-size: 20px">{{ firstChar(user_name) }}</span>
                  </v-avatar>
                </v-btn>
              </template>
              <v-card class="rounded-lg pa-0">
                <v-list>
                  <v-list-item :to="{ name: 'profile' }">
                    <v-list-item-avatar color="primary" size="48">
                      <span class="font-weight-medium" style="color: white; font-size: 30px">{{
                          firstChar(user_name)
                        }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-left">
                      <v-list-item-title class="font-weight-medium">{{ user_name }}</v-list-item-title>
                      <v-list-item-subtitle>Ver tu perfil</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list v-if="userBusiness.length > 0">
                  <v-subheader style="height: 30px">Tu Negocio</v-subheader>
                  <v-list-item :to="{ name: 'admin_business_edit_business', params:{ slug: userBusiness[0].slug} }">
                    <v-list-item-avatar size="35">
                      <img
                          v-if="userBusiness[0].logo && userBusiness[0].logo.thumb && userBusiness[0].logo.thumb !== ''"
                          :alt="userBusiness[0].name"
                          :src="userBusiness[0].logo.thumb">
                      <span v-else style="color: #0000008a; font-size: 20px; font-weight: bold">{{
                          firstChar(userBusiness[0].name)
                        }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-subtitle-2 text-left">
                      {{ userBusiness[0].name }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <!--                <v-divider></v-divider>-->
                <!--                <v-list>-->
                <!--                  <v-list-item :to="{ name: 'Subscription' }">-->
                <!--                    <v-list-item-avatar color="greyPrimary" size="35">-->
                <!--                      <v-icon small>mdi-credit-card-outline</v-icon>-->
                <!--                    </v-list-item-avatar>-->
                <!--                    <v-list-item-content class="text-subtitle-2">-->
                <!--                      Suscripción-->
                <!--                    </v-list-item-content>-->
                <!--                    <v-list-item-action>-->
                <!--                      &lt;!&ndash; sin color default para gratis - el mes que se le da gratis al iniciar-->
                <!--                           color primary para básica-->
                <!--                           color secondary para profesional &ndash;&gt;-->
                <!--                      <v-chip small color="primary">-->
                <!--                        <span class="font-weight-medium">Básica</span>-->
                <!--                      </v-chip>-->
                <!--                    </v-list-item-action>-->
                <!--                  </v-list-item>-->
                <!--                </v-list>-->
                <v-divider></v-divider>
                <v-list>
                  <v-list-item @click="logout()">
                    <v-list-item-avatar color="greyPrimary" size="35">
                      <v-icon small>mdi-logout</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-subtitle-2">
                      Cerrar sesión
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>
      </v-container>
    </v-toolbar>
  </div>

</template>

<script>
import router from "@/router";
import {getFirstChar} from '@/utils/data'

const SignIn = () => import(/* webpackPrefetch: true */ "@/components/SignIn");

export default {
  name: "Toolbar",
  components: {
    SignIn
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    loggedUser() {
      return this.$store.state.auth.user
    },
    userBusiness() {
      return this.$store.state.auth.business;
    },
    isLogged() {
      return this.$store.state.auth.loggedIn;
    },
    user_name() {
      const fullName = this.loggedUser.full_name;
      if (fullName.length > 18) {
        return fullName.split(' ')[0];
      } else {
        return fullName;
      }
    }
  },
  methods: {
    /**
     * Logout.
     * @returns {Promise<void>}
     */
    async logout() {
      try {
        await this.$store.dispatch('auth/logout');
        if (this.$route.name !== 'home')
          await router.push({name: 'home'});
      } catch (error) {
        console.log(error);
      }
    },
    firstChar: getFirstChar
  }
};
</script>
<style>

</style>
